import React from 'react'
import Slider from 'react-slick'

export default function fullImageSlider(props) {
  return (
    <div className='full-image-slider'>
          <div className='full-image-slider__container'>
            <div className='full-image-slider__slides'>
              <Slider
                className='full-image-slider__slide'
                slidesToShow={1}
                dots={true}
                arrows={false}
                appendDots={dots => (
                  <div className="full-image-slider__nav-tabs nav-tabs">
                    <ul className="slick-dots">{dots}</ul>
                  </div>
                )}
                dotsClass='full-image-slider__nav-tabs nav-tabs'
              >
                <div className='slide1'>
                  <img src='/images/teamshot1.jpg' alt='' />
                </div>
                <div className='slide1'>
                  <img src='/images/teamshot2.jpg' alt='' />
                </div>
                <div className='slide1'>
                  <img src='/images/teamshot3.jpg' alt='' />
                </div>
              </Slider>
            </div>
            <div className='full-image-slider__pattern'>
              <img src='/images/image-slider-pattern.png' alt='' />
            </div>
          </div>
        </div>
  )
}
