import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import Brands from "@components/brands"
import CaseSlider from '@components/case-slider'
import FullImageSlider from '@components/full-image-slider'
import SocialNetworks from '@components/social-networks'

export default function index(props) {
  return (
    <Layout location={props.location} title='Home' namespace='home'>
      <div className='hero hero-1'>
        <div className='hero-1__col-1'>
          <div className='title-label-1'>
            <div className='title-label-1__count'>
              01
            </div>
            <div className='title-label-1__seperator'></div>
            <div className='title-label-1__label'>
              Welcome
            </div>
          </div>
          <h1 className='hero-1__title'>
            Inspiring People to Build Great Software
          </h1>
          <Link className='primary-button' to='/case-studies'>
            See Our Work
            <div className='primary-button__icon'>
              <div className='icon-right-arrow-icon'></div>
            </div>
          </Link>
          <div className='scroll-down'>
            Scroll Down
          </div>
        </div>
        <div className='hero-1__hero-image hero-1__hero-image--home'>
          <div className='hero-1__image-wrapper'>
            <div className='hero-1__image hero-1__image--home'>
              <img src='/images/hero-home-bg.png' alt='' />
            </div>
            <div className='hero-1__bg-pattern hero-1__bg-pattern--home'>
              <img src='/images/hero-home-pattern.png' alt='' />
            </div>
          </div>
        </div>
        <div className='hero-1__col-2'>
          <SocialNetworks wrapperClassList='hero-1__hero-social'>
            <div className='social-layout-1__seperator'></div>
            <a className='social-layout-1__email' href='mailto:hello@wyeworks.com'>
              hello@wyeworks.com
            </a>
          </SocialNetworks>
        </div>
      </div>
      <section className='what-we-do'>
        <div className='masonry__row-1'>
          <div className='masonry__content-block content-block-1'>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  What We Do
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='content-block-1__content'>
              <h3>
                We bring positive change through software
              </h3>
              <p>
                We build software people love and enable companies to succeed, not only for the quality of our work but for the values instilled in the collaborative process which set the right environment to build trustworthy and long-lasting partnerships with sustainable success.
              </p>
            </div>
          </div>
          <div className='masonry__block'></div>
        </div>
        <div className='masonry__row-2'>
          <div className='masonry__row-2__col-1'>
            <div className='masonry__block masonry__block--2'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/web-development1.jpg' alt='web development' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/web-development1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                Web Development
              </h5>
              <p className='masonry__copy'>
                You’ve built your product. Now you need to expand your team bringing the expertise and leadership you need to further enable your company to grow with sustainable success.
              </p>
              <Link className='masonry__learn-link' to='/services'>
                Learn More
              </Link>
            </div>
          </div>
          <div className='masonry__row-2__col-2'>
            <div className='masonry__block masonry__block--1'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/process-consulting1.jpg' alt='process consulting' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/process-consulting1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                Process Consulting
              </h5>
              <p className='masonry__copy'>
                Your company got bigger and collaborating became a challenge. Now you need help implementing the right process for your team. We can diagnose your current situation and coach your team to be more engaged and productive.
              </p>
              <Link className='masonry__learn-link' to='/services'>
                Learn More
              </Link>
            </div>
            <div className='masonry__block masonry__block--3'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/innovation1.jpg' alt='tech review' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/innovation1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                Comprehensive Tech Review
              </h5>
              <p className='masonry__copy'>
                You grew and so did the complexity of your product. Now you need expert technical advice. We can help you scale your application or assess the quality of your code.
              </p>
              <Link className='masonry__learn-link' to='/services'>
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className='section--full-width'>
        <CaseSlider />
      </section>
      <section className='who-we-are'>
        <div className='content-block-2'>
          <div className='content-block-2__title-label'>
            <h2 className='h2-label'>
              <div className='h2-label__title'>
                Who We Are
              </div>
              <div className='h2-label__seperator'></div>
            </h2>
          </div>
          <div className='content-block-2__content'>
            <h3>
              We’re a team fueled by passion
            </h3>
            <p>
              We are a team of passionate individuals, who enjoy working and growing together. WyeWorks is all about trusting relationships, professionalism, and successful collaboration. We enjoy our friendly ambiance and we have fun working together. We believe these to be critical to every company’s success.
            </p>
          </div>
        </div>

        <FullImageSlider />

      </section>
      <Brands />
    </Layout>
  )
}
